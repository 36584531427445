import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useInterval } from '@chakra-ui/react';
import { add } from 'date-fns';

import { useAppSelector } from 'hooks/useAppSelector';
import {
  useLazyGetMenuItemsQuery,
  useRefreshTokenMutation,
} from 'app/services/api';

type PrivateRouteProps = {
  children: React.ReactElement;
  redirectPath?: string;
};

const RequireAuth = ({
  children,
  redirectPath = '/login',
}: PrivateRouteProps): React.ReactElement => {
  const rehydrated = useAppSelector((state) => state._persist.rehydrated);
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.auth.user);
  const [refreshToken, { isLoading }] = useRefreshTokenMutation();
  const [getMenuItems] = useLazyGetMenuItemsQuery();

  /**
   * Check if token exists and is due to expire soon
   *
   * @returns boolean
   */
  const tokenWillExpire = () => {
    const currDate = new Date();

    return (
      rehydrated &&
      user.token &&
      user.tokenExpiry &&
      // token due to expire within 5s
      add(currDate, { seconds: 5 }) > new Date(user.tokenExpiry) &&
      !isLoading
    );
  };

  const attemptTokenRefresh = async () => {
    await refreshToken();
  };

  useEffect(() => {
    if (tokenWillExpire()) {
      attemptTokenRefresh();
    }
  }, []);

  useInterval(() => {
    if (tokenWillExpire()) {
      attemptTokenRefresh();
    }
  }, 4500);

  useInterval(() => {
    getMenuItems('');
  }, 60000);

  return auth.isAuth ? children : <Navigate to={redirectPath} />;
};

export default RequireAuth;
