import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import deskLogo from '../../assets/back_button_desktop.png';
import mobileLogo from '../../assets/back_button_mobile.png';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
  const location = useLocation();
  const isResultsPage = location.pathname.includes('/results');
  const baseUrl = window.location.origin;
  const isMainPage = window.location.href === baseUrl + '/';
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const logo = isMobile ? mobileLogo : deskLogo;

  const navigate = useNavigate();
  useEffect(() => {
    const curLocation = sessionStorage.getItem('curLocation');
    if (curLocation !== null) {
      sessionStorage.setItem('prevLocation', curLocation);
    }
    if (window.location.href !== null) {
      sessionStorage.setItem('curLocation', window.location.href);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigateBackToDashboard = () => {
    const prevLocation = sessionStorage.getItem('prevLocation');
    if (!prevLocation) {
      const user_type = sessionStorage.getItem('user_type');
      if (user_type === 'STUDENT') {
        navigate('/curriculum');
      } else if (user_type === 'GUARDIAN') {
        navigate('/account');
      }
    } else {
      if (
        window.location.href === baseUrl + '/login' ||
        window.location.href === baseUrl + '/register' ||
        window.location.href === baseUrl + '/preview'
      ) {
        navigate('/');
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <div
      style={{
        marginTop: '10px',
      }}
    >
      {!isResultsPage && !isMainPage && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Image
            src={isMobile ? mobileLogo : logo}
            width={
              isMobile
                ? ['30px', null, '40px', '50px']
                : ['100px', null, '110px', '120px']
            }
            htmlWidth={isMobile ? '40px' : '100px'}
            alt='Back Button'
            ignoreFallback
            onClick={navigateBackToDashboard}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BackButton;
