import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { ai_api } from 'app/services/api';
export const TYPE_AI_MESSAGE = 'tutor';
export const TYPE_USER_MESSAGE = 'student';

export const MAX_USER_MESSAGE_COUNT = 10;

interface AIState {
  messages: (AIMessage | StudentMessage)[];
  waitingResponse?: boolean;
  selectedLesson?: string;
  userMessageCount: number;
}
interface AIMessage {
  type: typeof TYPE_AI_MESSAGE;
  title: string;
  discription: string;
  error?: boolean;
}

interface StudentMessage {
  type: typeof TYPE_USER_MESSAGE;
  history_type: string;
  options: string[];
  selected?: string;
  error?: boolean;
  errorMsg?: string;
  question: string;
}

const initialState: AIState = {
  messages: [
    {
      type: 'tutor',
      // type: 'AI-MESSAGE',
      title:
        "Hello👋! I'm your AI teacher, ready to assist you on your learning journey. Feel free to ask any questions, and let's explore the world of knowledge together",
      discription: '',
    },
  ],
  userMessageCount: 0,
  waitingResponse: false,
};

const aiTeacherSlice = createSlice({
  name: 'aiTeacher',
  initialState,
  reducers: {
    clearMessages(state) {
      state.messages = [];
    },
    setisWaiting(state, { payload }: PayloadAction<boolean>) {
      state.waitingResponse = payload;
    },
    setSelectedLesson(state, { payload }: PayloadAction<string>) {
      state.selectedLesson = payload;
    },
    incrementUserMessageCount: (state) => {
      state.userMessageCount += 1;
    },
    pushStudentQuery(state, { payload }: PayloadAction<StudentMessage>) {
      state.messages.push({
        ...payload,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ai_api.endpoints.createHistory.matchPending,
      (state, { meta }) => {
        state.waitingResponse = true;

        const message: StudentMessage = {
          type: TYPE_USER_MESSAGE,
          question: meta.arg.originalArgs.question,
          history_type: meta.arg.originalArgs.history_type,
          options: [],
        };
        state.messages = [...state.messages, message];
      },
    );
    builder.addMatcher(
      ai_api.endpoints.createHistory.matchFulfilled,
      (state, { payload }) => {
        state.waitingResponse = false;
        state.messages = [
          ...state.messages,
          {
            type: TYPE_AI_MESSAGE,
            title: payload.response,
            discription: '',
          },
        ];
      },
    );
  },
});

export const aiTeacherAction = aiTeacherSlice.actions;
export const incrementUserMessageCount = createAction(
  'aiTeacher/incrementUserMessageCount',
);

export const {
  pushStudentQuery,
  setisWaiting,
  clearMessages,
  setSelectedLesson,
} = aiTeacherSlice.actions;

export default aiTeacherSlice.reducer;
