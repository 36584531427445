import { useEffect } from 'react';
import { QuestItem } from 'components/nav/Header';
import {
  useLazyGetStudentQuestItemsQuery,
  useTagStudentQuestItemMutation,
} from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';

export const useNavLogic = () => {
  const { user, questItems } = useAppSelector((state) => state.auth);
  const [tagStudentQuestItem] = useTagStudentQuestItemMutation();
  const [getAllStudentQuestItems] = useLazyGetStudentQuestItemsQuery();

  const checkIsComplete = (label: string): boolean | undefined => {
    if (user.type === 'STUDENT') {
      const questItem = questItems.find(
        (quest: QuestItem) => quest.label === label.toLowerCase(),
      );
      return questItem && questItem.is_complete;
    } else return true;
  };

  const handleClick = (navItemLabel: string) => {
    if (user.type === 'STUDENT') {
      const clickedItem = questItems.find(
        (quest: QuestItem) => quest.label === navItemLabel.toLowerCase(),
      );

      if (clickedItem) {
        const updatedStudent: any = {
          student_username: String(user.username),
          quest_item_id: String(clickedItem.id),
        };
        tagStudentQuestItem(updatedStudent);
      }
    } else return;
  };

  useEffect(() => {
    if (user.type === 'STUDENT') {
      getAllStudentQuestItems();
    }
  }, []);

  return { checkIsComplete, handleClick };
};
