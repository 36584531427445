import { Button, Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useLazyGetAllQuestionsQuery } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  quizName: string;
  quizNumber: number;
}

const btnStyle = css(`
  height: 50px;
  font-size: 19px;
  border-radius: 12px;
  background-color: #2871D1;
`);

const LessonButtons: React.FC<Props> = ({ quizName, quizNumber }) => {
  const [getAllQuestions, response] = useLazyGetAllQuestionsQuery();
  const navigate = useNavigate();
  const { setQuizInProgress, addNotification, resetCurrentQuestion } =
    useActions();
  const startQuiz = () => {
    getAllQuestions(String(quizNumber));
  };
  useEffect(() => {
    if (response.isSuccess) {
      const formattedQuizName = quizName.replace(' ', '-').replace('/', '-');
      resetCurrentQuestion();
      setQuizInProgress(quizNumber);
      navigate(`${formattedQuizName}`);
    } else if (response.error) {
      const error = response.error as {
        status: number;
        data: { error: string };
      };
      if (error.status === 400) {
        addNotification(error.data.error);
      } else {
        addNotification('An error occured. Please try again later.');
      }
    }
  }, [response]);

  return (
    <Flex
      w={'100%'}
      mt={['15px', null, null, '0']}
      flexDir={['row', null, null, 'row']}
      justify={['center', null, null, 'center']}
    >
      <Button
        m={['5px 0', null, null, '0 30px 20px 15px']}
        w={['70%', null, null, '50%']}
        css={btnStyle}
        onClick={startQuiz}
      >
        Start
      </Button>
    </Flex>
  );
};

export default LessonButtons;
