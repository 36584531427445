import React, { useEffect } from 'react';
import { Checkbox, Flex, Box } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { renderMaths } from 'utils/katexUtils';

interface Props {
  options: any;
}

const MultiAnswerOptions: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  useEffect(() => {
    renderMaths(document.getElementsByTagName('div'));
  }, [options]);

  const handleClick = (event: any) => {
    event.preventDefault();
    const answer = Object.assign([], userAnswer);
    const newEntry = event.currentTarget.value;
    if (answer !== undefined) {
      if (answer.includes(newEntry)) {
        answer.splice(answer.indexOf(newEntry), 1);
      } else {
        answer.push(newEntry);
      }
    }
    selectAnswer(answer);
  };

  return (
    <Flex flexDir={'column'} align={'flex-start'}>
      {options.map((option: any) => {
        return (
          <Checkbox
            onChange={handleClick}
            m={'10px 20px 0 0'}
            key={option.id}
            value={option.id}
            isChecked={
              userAnswer !== undefined && userAnswer.includes(option.id)
            }
            paddingLeft={'3px'}
          >
            <Box
              // Set 0 margins on `p` elements to prevent overflow
              sx={{ p: { margin: 0 } }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(option.content),
              }}
            />
          </Checkbox>
        );
      })}
    </Flex>
  );
};

export default MultiAnswerOptions;
