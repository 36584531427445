import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  Text,
  Box,
  Flex,
  Link,
} from '@chakra-ui/react';
import { useNavLogic } from 'hooks/useNavLogic';
import { UserProfile } from 'features/userProfile/userSlice';
import { INavItem } from 'components/nav/Header';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';
import { useAppSelector } from 'hooks/useAppSelector';
import { FaExclamation } from 'react-icons/fa';

interface Props {
  navItems: INavItem[];
  userDetails: UserProfile;
}

const DesktopNav: React.FC<Props> = ({ navItems, userDetails }) => {
  const { urls, skinChoice } = useAppSelector((state) => state.user);
  const { checkIsComplete, handleClick } = useNavLogic();

  return (
    <Menu>
      <MenuButton
        px={0}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        aria-label={'Toggle Menu'}
        onClick={() => handleClick('profile_pic')}
      >
        {!checkIsComplete('profile_pic') && <FaExclamation color='gold' />}

        <Avatar
          size={'lg'}
          src={
            urls.thumbnail +
            getThumbnailFilename(skinChoice.filename.split('/').pop() as string)
          }
          border={'2px solid #fff'}
        />
      </MenuButton>
      <MenuList
        flexDirection={'column'}
        w={'90vw'}
        maxW={'18rem'}
        p={'2.25rem'}
        color={'button.200'}
        fontWeight={600}
        border={'none'}
        borderRadius={'1rem'}
        zIndex={9}
        backgroundColor={'white'}
        shadow={'none'}
      >
        <Flex alignItems={'center'} pb={'0.625rem'}>
          <Avatar
            size={'md'}
            src={
              urls.thumbnail +
              getThumbnailFilename(
                skinChoice.filename.split('/').pop() as string,
              )
            }
          />
          <Box ml={5}>
            <Text fontWeight={700} m={0} wordBreak={'break-all'}>
              {userDetails.userName}
            </Text>
            {userDetails.name && (
              <Text fontWeight={400} m={0}>
                {userDetails.name}
              </Text>
            )}
          </Box>
        </Flex>
        {navItems.map((navItem) => {
          const isExternalUrl = navItem.href.toString().indexOf('http') !== -1;
          return (
            <React.Fragment key={navItem.label}>
              {navItem.topDivider && (
                <MenuDivider my={4} color={'button.200'} />
              )}
              <Flex
                flexDirection={'row'}
                alignItems={'center'}
                onClick={() => handleClick(navItem.label)}
              >
                <React.Fragment>
                  <Link
                    as={isExternalUrl ? undefined : RouterLink}
                    to={isExternalUrl ? '' : navItem.href}
                    href={isExternalUrl ? navItem.href.toString() : ''}
                    isExternal={isExternalUrl}
                    display={'block'}
                    p={'0.5rem 0'}
                    _hover={{ color: 'button.100', bgColor: '#fff' }}
                    _focus={{ color: 'button.100', bgColor: '#fff' }}
                    _active={{ color: 'button.100', bgColor: '#fff' }}
                  >
                    {navItem.label}
                  </Link>
                  {!checkIsComplete(navItem.label) && (
                    <FaExclamation color='gold' />
                  )}
                </React.Fragment>
              </Flex>
            </React.Fragment>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default DesktopNav;
