import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  Heading,
  Box,
  Image,
  Text,
  Textarea,
  Button,
  Collapse,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  TYPE_AI_MESSAGE,
  TYPE_USER_MESSAGE,
  MAX_USER_MESSAGE_COUNT,
} from './aiTeacherSlice';
import { getRandomInt } from './utils';

import { useLazyCreateHistoryQuery } from 'app/services/api';
// import BackButton from 'components/buttons/BackButton';

const one = 'https://cdn.valearnis.com/backgrounds/ai/acient-ruins.svg';
const two = 'https://cdn.valearnis.com/backgrounds/ai/mount-fuji.png';
const three = 'https://cdn.valearnis.com/backgrounds/ai/natural.svg';
const four = 'https://cdn.valearnis.com/backgrounds/ai/tokyotower.png';

const backgrounds = [one, two, three, four];

const aiTeacher = 'https://cdn.valearnis.com/backgrounds/ai/robot-ai.png';

const AILessonPage: React.FC = () => {
  const [background, setBackground] = useState<string | undefined>();
  const [text, setText] = React.useState<string>('');
  const bottomMsgRef = useRef<HTMLDivElement>(null);
  const { avatar } = useAppSelector((state) => state.user);

  const aiState = useAppSelector((state) => state.aiTeacher);
  const selectedLesson = aiState.selectedLesson;
  const userMessageCount = useAppSelector(
    (state) => state.aiTeacher.userMessageCount,
  );

  const aiMessages = aiState.messages;
  const lastUserMessage = aiMessages
    .slice()
    .reverse()
    .find((message) => message.type === TYPE_USER_MESSAGE);

  const lastAiMessage = aiMessages
    .slice()
    .reverse()
    .find((message) => message.type === TYPE_AI_MESSAGE);

  const [createHistory] = useLazyCreateHistoryQuery();
  useEffect(() => {
    if (bottomMsgRef.current) {
      bottomMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [aiState.messages]);

  const handleSendMessage = async () => {
    if (text !== '') {
      const data = {
        history_type: 'Short',
        question: text,
      };
      await createHistory(data);
      setText('');
    }
  };

  useEffect(() => {
    const randomInt = getRandomInt(0, 3);
    setBackground(backgrounds[randomInt]);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const AiMessageBubble = (
    key: string,
    message: string,
    discription: string,
  ) => {
    return (
      <Box
        height={'auto'}
        display={'flex'}
        key={key}
        alignItems={'flex-start'}
        flexDir={'column'}
        padding={1}
        position={'relative'}
      >
        <Box
          marginLeft={'3 rem'}
          bgColor={'green'}
          maxW={'80%'}
          padding={'1rem'}
          borderRadius={'20px'}
          position={'relative'}
        >
          <Text
            margin={0}
            fontWeight={'semibold'}
            fontSize={'0.5 rem'}
            lineHeight={1}
          >
            {message}
          </Text>
          <Text fontWeight={'light'} fontSize={'sm'}>
            {discription}
          </Text>
        </Box>
        <Box>
          <Image src={aiTeacher} height={'3rem'} />
        </Box>
      </Box>
    );
  };

  const ResponseBubble = (
    key: string,
    message?: string,
    selected?: string,
    error?: string,
  ) => {
    return (
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexDir={'row-reverse'}
        padding={2}
        key={key}
      >
        <Box>
          <Image src={avatar} height={'3rem'} />
          {/*change it so it only displays the portrait of the avatar */}
        </Box>
        <Box
          bgColor={'brand.500'}
          maxW={'80%'}
          padding={'1rem'}
          borderRadius={'20px'}
          marginLeft={2}
        >
          <Text
            margin={0}
            fontSize={'0.5 rem'}
            fontWeight={'semibold'}
            lineHeight={1}
          >
            {message}
          </Text>
          {selected && selected.length > 1 ? (
            <Text marginTop={1} fontSize={'sm'} size='sm'>
              Selected : {selected}
            </Text>
          ) : null}
          <div ref={bottomMsgRef} />
        </Box>

        {error ? <Text colorScheme='error'>{error}</Text> : null}
      </Box>
    );
  };
  const AiLessonBubble = (
    key: string,
    message: string,
    description: string,
  ) => {
    return (
      <Box
        height={'100%'}
        display={'flex'}
        flex={1}
        key={key}
        alignItems={'flex-start'}
        flexDir={'column'}
        padding={2}
        overflowY={'auto'}
      >
        <Box zIndex={1}>
          <Image src={aiTeacher} height={['6rem', '8rem', '10rem']} />
        </Box>
        <Box
          marginLeft={['4rem', '6rem', '8rem']}
          marginTop={['-2rem', '-3rem', '-4rem']}
          bgColor={'green'}
          maxW={'80%'}
          padding={'1.3rem'}
          borderRadius={'20px'}
          borderRightRadius={'5px'}
        >
          <Text
            margin={0}
            fontWeight={'semibold'}
            fontSize={'1.6rem'}
            lineHeight={1}
          >
            {message}
          </Text>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Box>
      </Box>
    );
  };

  const ResponseInputBubble = () => {
    return (
      <>
        <Box
          display={'flex'}
          alignItems={'flex-end'}
          flexDir={'row-reverse'}
          padding={2}
        >
          <Collapse in={true}>
            <Box
              position={'relative'}
              backgroundColor='rgba(255, 255, 255, 0.5)' // 50% transparent white
              display={'flex'}
              flexDir={'column'}
              width={['90vw', '70vw', '60vw', '50vw']}
              height={'25vh'}
              borderTopRadius={'30px'}
              borderBottomRadius={'7px'}
              border={'2px solid #000'}
              overflowY={'auto'}
              padding={'10px'}
            >
              <Box height={'60vh'} overflow={'auto'} mb={1}>
                {/* {lastAiMessage &&
                AiMessageBubble(
                  uuidv4(),
                  lastAiMessage.type === TYPE_AI_MESSAGE
                    ? lastAiMessage.title
                    : '',
                  lastAiMessage.type === TYPE_AI_MESSAGE
                    ? lastAiMessage.discription
                    : '',
                )} */}
                {lastUserMessage &&
                  ResponseBubble(
                    uuidv4(),
                    lastUserMessage.type === TYPE_USER_MESSAGE
                      ? lastUserMessage.question
                      : '',
                  )}
              </Box>
              <Box
                bgColor={'brand.500'}
                padding={2}
                borderRadius={4}
                width={'100%'}
                height={'60%'}
                display={'flex'}
                flexDir={'row'}
                flexWrap={'wrap'}
                alignItems={'center'}
                justifyContent={'space-evenly'}
              >
                <CircularProgress
                  value={(userMessageCount / MAX_USER_MESSAGE_COUNT) * 100}
                  color='red'
                  thickness='15px'
                  flex={0.1}
                >
                  <CircularProgressLabel>
                    {MAX_USER_MESSAGE_COUNT - userMessageCount}
                  </CircularProgressLabel>
                </CircularProgress>
                <Textarea
                  variant='flushed'
                  placeholder='Please type your question about the lesson here'
                  resize={'none'}
                  width={'100%'}
                  minW={'25%'}
                  ml={2}
                  flex={0.8}
                  value={text}
                  overflow={'hidden'}
                  onChange={(e) => {
                    setText(e.target.value ?? '');
                  }}
                />
                <Button
                  paddingTop={'8.1px'}
                  onClick={handleSendMessage}
                  isDisabled={userMessageCount >= MAX_USER_MESSAGE_COUNT}
                  size={'xs'}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </>
    );
  };
  return (
    <Flex
      position={'relative'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      flexDirection={'column'}
      flexWrap={'wrap'}
      width={'100%'}
      minHeight={'100vh'}
      color={'#FFF'}
      backgroundColor={'brand.800'}
      backgroundGradient={'linear(brand.700, brand.800)'}
      backgroundPosition={'bottom'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      style={background ? { backgroundImage: `url(${background})` } : {}}
      overflow={'hidden'}
    >
      {/* <BackButton /> */}
      <Heading as={'h1'} fontSize={'2rem'} margin={'60px 0 0px'}>
        Ai Teacher
      </Heading>

      <Box
        width={['100%', '90%', '80%', '80%', '1500px']}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'60vh'}
        padding={'1rem 2rem 2rem'}
        overflowX={'hidden'}
      >
        {AiLessonBubble(
          uuidv4(),
          `Welcome to your ${selectedLesson ? selectedLesson : ''} Lesson!`,
          lastAiMessage && lastAiMessage.type === TYPE_AI_MESSAGE
            ? lastAiMessage.title
            : '',
        )}
      </Box>
      {ResponseInputBubble()}
    </Flex>
  );
};

export default AILessonPage;
