import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@chakra-ui/react';
import { KatexOptions, render as katexRender } from 'katex';

// import store from 'app/store';
import { useUrlParts } from 'hooks/useUrlParts';
import { useAppSelector } from 'hooks/useAppSelector';

import LargeImage from 'features/lessons/templates/zoomedImage/LargeImage';
import Question from 'features/lessons/QuestionTemplate';
import QuizTitle from 'features/lessons/QuizTitle';
// import { selectAnswer } from 'features/questions/questionsSlice';
// import TheoryTemplate from 'features/lessons/lessonTemplates/TheoryTemplate';
import PaddedContainer from 'components/containers/PaddedContainer';
import PreviewLessonNavStepper from 'components/pagination/PreviewLessonNavStepper';
import LoadingCircle from 'components/icons/LoadingCircle';
import 'katex/dist/katex.min.css';
import { useActions } from 'hooks/useActions';
import ApproveLessonButton from '../lessons/ApproveLessonButton';

// export const submitAnswer = (answer: any) => {
//   // store.dispatch(selectAnswer({ answer }));
// };

const LessonPreview: React.FC = () => {
  {
    /* TODO: dynamically handle background images */
  }

  const { resetCurrentQuestion } = useActions();
  const navigate = useNavigate();
  const urlParts = useUrlParts();

  useEffect(() => {
    const mathElements = document.getElementsByClassName('math');
    const macros: KatexOptions['macros'] = [];
    for (let i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;

      if (texText?.nodeValue && mathElements[i].tagName === 'SPAN') {
        katexRender(texText.nodeValue, mathElements[i] as HTMLElement, {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: false,
          macros: macros,
          fleqn: false,
        });
      }
    }
  });

  const { questions, currentQuestion, lessonLoading, lessonReady, lessonId } =
    useAppSelector((state) => state.questions);
  const largeImageVisible = useAppSelector((state) => state.largeImage.visible);
  const { quizInProgress } = useAppSelector((state) => state.progress);
  useEffect(() => {
    if (quizInProgress === 0) {
      urlParts.pop();
      navigate('/' + urlParts.join('/'));
    }
    resetCurrentQuestion();
  }, []);

  return (
    <PaddedContainer
      display={'flex'}
      flexDir={'column'}
      mt={['-5em', null, '0']}
      padding={['8em 28px 28px 28px', null, '28px']}
      textAlign={lessonLoading ? 'center' : 'left'}
      // height={'75vh'}
    >
      {!lessonReady && <ApproveLessonButton id={lessonId} />}
      {largeImageVisible && <LargeImage />}
      {!lessonLoading && <QuizTitle />}
      <Heading as={'h2'} mb={'2rem'} fontSize={'0.75rem'} fontWeight={'400'}>
        {!lessonLoading && questions[currentQuestion].questionNum}
      </Heading>
      {lessonLoading ? (
        <LoadingCircle text={'Loading Lesson!'} />
      ) : (
        <Question />
      )}
      <PreviewLessonNavStepper />
    </PaddedContainer>
  );
};

export default LessonPreview;
