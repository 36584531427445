import { Heading, Flex, Image, Text, Box } from '@chakra-ui/react';
import { useGetAllReportsQuery } from 'app/services/api';
import { RootState } from 'app/store';
import ButtonLink from 'components/buttons/ButtonLink';
import LoadingCircle from 'components/icons/LoadingCircle';
import React from 'react';
import { useSelector } from 'react-redux';
import { lowerCase } from 'utils/stringUtils';
import MoreDetails from './MoreDetails';
import { getBestTopic, getTopics } from './reportUtils';
import FixPositionBackButton from 'components/buttons/FixPositionBackButton';
// import MoreDetails from './MoreDetails';
const attributeStyles = {
  m: '2px 5px 2px 0',
  fontSize: '12px',
};

const ReportsPage: React.FC = () => {
  const { reports } = useSelector((state: RootState) => state.progress);
  const { isLoading } = useGetAllReportsQuery('');

  return (
    <Flex
      w={'100vw'}
      bgColor={'brand.800'}
      bgGradient={'linear(brand.700, brand.800)'}
      bgPos={'bottom'}
      bgSize={'cover'}
      color={'#FFF'}
      textAlign={'center'}
      pos={'relative'}
      p={['120px 0', '60px 0']}
      minH={'100vh'}
      flexDir={'column'}
      alignItems={'center'}
      justify={'center'}
    >
      <FixPositionBackButton />
      <Heading fontSize={['22px', null, '30px']} mb={'30px'}>
        Reports
      </Heading>
      <Flex
        align={'center'}
        justify={'center'}
        flexDir={['column', 'column', 'row', 'row', 'row', 'row']}
        wrap={'wrap'}
        textAlign={isLoading ? 'center' : 'left'}
        w={['100%', null, '650px']}
        m={['0 auto']}
      >
        {isLoading ? (
          <LoadingCircle text={'Fetching Reports!'} />
        ) : (
          Object.keys(reports.subjects).map((subject) => {
            return (
              <Flex
                key={subject}
                m={['10px', null, '30px']}
                p={'15px'}
                w={'258px'}
                bg={'brand.600'}
                borderRadius={'10px'}
                flexDir={'column'}
              >
                <Box as={'span'} mb={'15px'}>
                  <Image
                    display='inline-block'
                    mr={'20px'}
                    src={
                      'https://cdn.valearnis.com/icons/' +
                      lowerCase(subject) +
                      '.svg'
                    }
                    alt={subject + '-icon'}
                  />
                  <Text
                    display={'inline-block'}
                    fontSize={'16px'}
                    fontWeight={'bold'}
                  >
                    {subject}
                  </Text>
                </Box>
                <Box mb={'10px'}>
                  <Box as={'span'}>
                    <Text display={'inline-block'} {...attributeStyles}>
                      Average score:
                    </Text>
                    <Text
                      display={'inline-block'}
                      {...attributeStyles}
                      fontWeight={'bold'}
                    >
                      {typeof reports.subjects[subject].average === 'string'
                        ? 'No data'
                        : reports.subjects[subject].average + '%'}
                    </Text>
                  </Box>
                  <Text {...attributeStyles}>
                    {'Best sub topic: ' + getBestTopic(reports, subject)}
                  </Text>
                  <Text {...attributeStyles}>
                    {'Lessons completed: ' +
                      reports.subjects[subject].times_studied}
                  </Text>
                </Box>
                <MoreDetails topics={getTopics(reports, subject)} />
              </Flex>
            );
          })
        )}
      </Flex>
      <ButtonLink to={'/pdf-report'}>{'Save as PDF'}</ButtonLink>
    </Flex>
  );
};

export default ReportsPage;
