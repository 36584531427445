import { Flex, Heading, Text, Image, Box } from '@chakra-ui/react';
import { useLazyGetPreviewLessonsQuery } from 'app/services/api';
import { useActions } from 'hooks/useActions';
// import { ChevronDown } from 'components/icons';
// import ChevronUp from 'components/icons/ChevronUp';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  subject: string;
  description: string;
  topics: string[];
  topicDescriptions: { [key: string]: string };
  setSubject: React.Dispatch<React.SetStateAction<string | null>>;
}

const CurriculumBox: React.FC<Props> = ({ subject, setSubject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { setQuizInProgress } = useActions();
  const [getLesson] = useLazyGetPreviewLessonsQuery();
  console.log(setIsOpen);
  return (
    <>
      {isOpen ? (
        <>
          {/* <Flex>
            <Text m={'15px'}>{description}</Text>
          </Flex>
          <Flex
            w={'100%'}
            flexDir={'column'}
            m={'20px 0'}
            // borderBottom={'white solid 1px'}
          >
            {topics.map((topic) => (
              <Flex
                key={topic}
                alignItems={'center'}
                justifyContent={'space-between'}
                w={'100%'}
              >
                <Heading
                  textAlign={'left'}
                  w={'20%'}
                  fontSize={'1rem'}
                  letterSpacing='0.1rem'
                  ml={'20px'}
                >
                  {topic}
                </Heading>
                <Text w={'75%'} textAlign={'left'} m={'15px'}>
                  {topicDescriptions[topic]}
                </Text>
              </Flex>
            ))}
          </Flex> */}
        </>
      ) : (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          flexDir={'column'}
          w={['80%', '60%', '20%']}
          backgroundSize={'cover'}
          m={['10px 15px', '0 15px']}
          position={'relative'}
        >
          <Image
            h={'100%'}
            src={`https://manezco.sfo3.digitaloceanspaces.com/preview/${subject.toLowerCase()}.png`}
            onClick={() => {
              setIsOpen(true);
              setSubject(subject);
            }}
          />
          <Heading
            position={'absolute'}
            letterSpacing='0.1rem'
            fontSize={'1.2rem'}
            top={'20px'}
          >
            {subject}
          </Heading>
          <Box
            borderBottom={'1px white solid'}
            position={'absolute'}
            bottom={'20px'}
          >
            <Text
              fontWeight={'600'}
              letterSpacing={'0.06rem'}
              textDecoration={'none'}
              lineHeight={'0.3rem'}
              cursor={'pointer'}
              onClick={() => {
                setQuizInProgress(1);
                getLesson(subject);
                navigate('/preview-lesson/' + subject);
              }}
            >
              Preview Lesson
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};
export default CurriculumBox;
