import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const ChooseUser: React.FC = () => {
  return (
    <Box m={'0 28px'} textAlign={'center'}>
      <Heading as={'h1'}>404 | Not Found</Heading>
      <Text mt={2}>
        The page you were looking for doesn&apos;t seem to exist...
      </Text>
    </Box>
  );
};

export default ChooseUser;
